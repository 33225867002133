<template>
  <div class="code-review-page" @scroll="scrolling()">
    <breadcrumbs
        :breadcrumbs="breadcrumbs"
        :scroll="scroll"
        @goBack="back"
    />
    <section class="code-review__banner">
      <img
          class="code-review__banner-img"
          src="@/assets/magento/magento-custom-development/magento-custom-development.jpg"
          alt="Custom home delivery date & time solution for ecommerce"
          loading="lazy"
          modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
      />
      <h1 class="code-review__banner-title">
        CODE REVIEW: HOW DOES IT ACTUALLY WORK?
      </h1>
    </section>
    <div class="container code-review section_holder">
      <div class="section_title">
        <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
      </div>
      <div class="section_content">
        <div class="section_content__item">
          <p>
            In the world of rapidly developing startups & successful ecommerce product distribution platforms, every decision you make can dramatically affect your business. Either helping you be triumphant in the competitive market, or losing your clients; and as a result, your profits.
          </p>
          <p>
            Imagine that you’re the owner of a growing online store with thousands of potential customers visiting your website each day. You may have an awesome product, carefully developed and tailored to your target audience’s needs, and it’s ready to be offered for purchase. Unfortunately there’s something on your website that simply doesn’t work properly and subsequently influences your customers to leave the site immediately. What would you do?
          </p>
          <p>
            But first, let’s face it - even the pros make mistakes occasionally, so some small failure in your developer’s code can easily lead to defects in the algorithm at a later stage.
          </p>
          <p>
            There are various reasons why a dev team can miss this. Either the algorithm with a defect is one which is rarely used. Or, for example, it has several defects in different places that fully or partially compensate for their impact on the end result.
          </p>
          <p>
            The real problem occurs when a defect turns into a complete malfunction which your site visitors notice. If this malfunction is serious, it can lead to a total website crash. Needless to say, every minute of an inaccessible website means financial losses for a business owner.
          </p>
          <p>
            At <b>WDEVS</b>, we are experts in the field of web development, and often speak about the importance of effective code quality management. And code review is one of the principal tasks involved in preparing to launch your project.
          </p>
          <p>
            By going through the source code line by line, this review helps find any security vulnerabilities, identify potential risks for website performance, and provides easy-working solutions to eliminate any weak points.
          </p>
          <p>
            It also shows the reasons for poor performance and allows your developers to manage the situation… before the unthinkable happens and the defects emerge while the site is already running.
          </p>
          <p>
            Let’s take a closer look at what a code review actually is and what benefits you, as the client, can expect from it.
          </p>
          <h2 class="section_content__item-title section_content__item-title--icon">
            <span class="section_content__item-title-icon">
              <img
                v-svg-inline
                :data-src="require('@/assets/icons/code-security-icon.svg')"
                alt=""
              />
            </span>
            WHY IS A CODE SECURITY CHECK IMPORTANT?
          </h2>
          <p>
            As mentioned previously, any software created by hand can potentially contain mistakes, security bugs, architectural errors, or business logic flaws that may affect your website’s security, as well as the users’ experience.
          </p>
          <p>
            If identified only at a later stage, then eliminating these vulnerabilities can become a more time-consuming and expensive process. Knowing your weak points, make them much easier and quicker to fix. Thus, code review saves you time and money, and also helps you avoid potential negative feedback from your customers.
          </p>
          <p>
            Here's a sample checklist to help you understand whether you need a code review:
          </p>
          <ul>
            <li>Buffer overflows;</li>
            <li>Usage of obsolete libraries/frameworks;</li>
            <li>The website’s applications are unreliable and crash from time to time for no apparent reason;</li>
            <li>Code repetition which leads to further expenses on refactoring;</li>
            <li>Poor performance since applications run slower than expected, which leads to decreasing usability;</li>
            <li>Unexplained memory leakage;</li>
            <li>Code injection flaws;</li>
            <li>Adding new features become difficult because of seemingly unrelated parts of the system failing;</li>
            <li>Components of the code are too dependent on the developer who created them;</li>
            <li>Customer complaints about your website’s performance have noticeably increased.</li>
          </ul>
          <p>
            Please bear in mind that the information above does not include a complete list of potential risks, problems and failures that you may experience on your website. At first glance, your code may seem to work perfectly, but it could possibly still have bad coding practices implemented. Unfortunately you’ll only know that for sure when the coding unexpectedly stops working.
          </p>
          <p>
            Of course, there are different ways you can handle this situation; but involving an external team can be especially helpful as it provides you with an impartially professional view to the problem, as well as reduces your in-house team workload. After all, it’s always nice to have a second opinion on a business matter this serious.
          </p>
          <h2 class="section_content__item-title section_content__item-title--icon">
            <span class="section_content__item-title-icon">
              <img
                v-svg-inline
                :data-src="require('@/assets/icons/code-review-icon.svg')"
                alt=""
              />
            </span>
            CODE REVIEW SERVICES BY WDEVS
          </h2>
          <p>
            As is already obvious, code review demands a thorough approach which is aimed at refining the end-quality of your project’s code, and therefore improving your site’s customer experience.
          </p>
          <p>
            <b>WDEVS</b> not only brings the service itself to reality, but also ensures that customers gain as much value as possible from this process. Our team has experts in a wide range of programming languages to help you root out security vulnerabilities, find hidden bugs, and fix performance issues before they become a problem and impair the functionality of your website.
          </p>
          <p>
            Whether you're a major ecommerce company or a small startup, we know how to help you use your existing tools. There’s no need to overpay for something you don’t need or understand. <b>WDEVS</b> will investigate your existing code and provide effective solutions for your needs.
          </p>
          <h2 class="section_content__item-title">
            So, how does it work in practice?
          </h2>
          <p>
            The estimated scope of work depends on the complexity of your system and on the results you are looking to achieve after the code review. Typical scenarios followed by <b>WDEVS</b>; as part of our independent code review services, can be described as follows:
          </p>
          <p>
            <b>1.</b> If you’re 100% sure of your dev team, and they’re developing accurate code in terms of methodology suggested by the selected framework - then it wouldn’t take too much time & money to provide code assessment.
          </p>
          <p>
            The quickest approach would be an <b>express review</b>. This includes checking random code components and classes, and analyzing their structure in regards to the used framework.
          </p>
          <p>
            An express review usually takes about <b>4 hours</b>, as well as the time it takes to analyze and report the evidence of the coding problems to the client.
          </p>
          <p>
            <b>2.</b> Now, imagine a situation where the developer team has strict time limits, a lack of experienced specialists in certain areas, as well as constantly pressurized working conditions. They can hardly be expected to write perfect code.
          </p>
          <p>It doesn't mean the coding is poor - it just needs proper attention to avoid unsatisfying performance issues in the future. In this case, it's reasonable to manage a <b>standard code review</b>, which will take the architectural solutions and DB structure into consideration.</p>
          <p>
            During a standard code review procedure, we will investigate the following:
          </p>
          <p class="section_content__paragraph">
            <span class="section_content__paragraph-icon">
              <img
                v-svg-inline
                :data-src="require('@/assets/icons/design-architecture-icon.svg')"
                alt=""
              />
            </span>
            <span class="section_content__paragraph-text">
              <i>Design and Architecture.</i> Application architecture, database structure and database type compliance for your project’s tasks, as well as checking existing technologies and frameworks for compliance with the project goals.
            </span>
          </p>
          <p class="section_content__paragraph">
            <span class="section_content__paragraph-icon">
              <img
                v-svg-inline
                :data-src="require('@/assets/icons/code-quality-icon.svg')"
                alt=""
                />
            </span>
            <span class="section_content__paragraph-text">
              <i>Code Quality</i>. Includes issues in different categories with detailed descriptions. All investigated
              issues are categorized as either “recommended to fix” or “critical”. We also provide information indicating
              whether the code should be simplified, if it’s poorly commented, as well as any other potential problems.
            </span>
          </p>
          <p class="section_content__paragraph">
            <span class="section_content__paragraph-icon">
              <img
                v-svg-inline
                :data-src="require('@/assets/icons/maintainability-icon.svg')"
                alt=""
              />
            </span>
            <span class="section_content__paragraph-text">
              <i>Maintainability.</i> We analyze how the code is structured, whether it is testable, and how much redundancy is present in the code base.
            </span>
          </p>
          <p class="section_content__paragraph">
            <span class="section_content__paragraph-icon">
              <img
                v-svg-inline
                :data-src="require('@/assets/icons/scalability-icon.svg')"
                alt=""
              />
            </span>
            <span class="section_content__paragraph-text">
              <i>Scalability.</i> A scalable code does not need frequent modifications to maintain performance, and adapts
              as quickly as your business requires. During the review, we analyze the type of your code's scalability, how
              it handles each interaction request based entirely on the information accompanying it, and whether some
              parts of the code can possibly hinder scaling the system during later stages. We also recommend possible
              changes to ensure optimal scalability.
            </span>
          </p>
          <p class="section_content__paragraph">
            <span class="section_content__paragraph-icon">
              <img
                v-svg-inline
                :data-src="require('@/assets/icons/security-check-icon.svg')"
                alt=""
              />
            </span>
            <span class="section_content__paragraph-text">
              <i>Security Check.</i> We identify potential vulnerabilities such as malicious code, and prioritize review
              results based on what needs to be fixed first — taking your business goals and level of risk into account.
            </span>
          </p>
          <p>
            As for the completion time. Of course, it depends on the size of your project, but roughly speaking - it takes about <b>24 hours.</b>
          </p>

          <p>
            <b>3.</b> Large-scale projects often experience bigger risk levels due to their structure complexity, which can result in out-of-control costs and major project failures. That’s why large companies pay special attention to quality assurance and risk prevention, since even a small mistake can be detrimental to your business.
          </p>
          <p>
            As a <b>Premium code review service</b>, we provide the ultimate analytics service - pursuing a deep investigation into the source code, the architecture, the DB structure, as well as the server's settings. Beside the services mentioned in the previous option, we also offer the following:
          </p>
          <p>
            <i>Test review.</i> Includes an analysis of unit tests, code coverage, tests’ quality. We will check the failure conditions, code readability, speed of execution, etc.
          </p>
          <p>
            <i>UI Review.</i> We investigate how UI is implemented on your website, from the code perspective. If it’s a mobile application, we also check if the developed solution follows the style’s guidelines for a specific platform.
          </p>
          <p>
            <b>WDEVS</b> has a trustworthy team of professional developers, and technologies which go well beyond basic vulnerability scans. We are ready to offer a custom-tailored approach to your business which means that we’ll provide both solution flexibility and personal recommendations, as well as a step-by-step guide to improve your business’s resilience and performance.
          </p>
          <p>
            Of course, due to the exclusive focus on your project in this case, code review will demand more time to develop a detailed evaluation, as well as compile a complete plan for potential risk elimination. The price can be about <b>10% of the development time</b> spent for the project creation.
          </p>
          <p>
            We will analyze your project from a 3rd party perspective. This guarantees that you will get an independent, high-quality review which will help you to achieve your business goals. What’s even more important, is that the security of your code and intellectual property will be our top priority. <b>WDEVS</b> follow the best practices and highest work standards to ensure your data is safe.
          </p>
          <p>
            Let's discuss your project’s needs and find efficient ways to optimize your code, provide higher security and improve the overall performance of your project!
          </p>
        </div>
        <div>
          <div class="footer-blog-item">
            <div
                class="btn-read-more primary contacts-button small"
                id="show-modal"
                @click="this.$router.push('/blog')"
            >
              <span class="button-content">
                <span class="icon"
                ><img
                    v-svg-inline
                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                    alt=""
                /></span>
                Read more our stories
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import Breadcrumbs from "/src/components/Breadcrumbs";
import {mapState} from "vuex";

export default {
  name: "BlogCodeReview",
  data() {
    return {
      backHistory:history.state.back,
      showModal:false,
      tags:['Healthcare','Delivery','Calendar','Schedule','Delivery date'],
      scroll: false,
      position: null,
      text: `
      Would you like to receive more examples of what we are able to help you achieve?<br />
      Do you still have doubts about your idea and believe that it will be too hard to implement?<br/>
      Would you like us to propose ways of turning your ideas into reality?
      `,
      breadcrumbs: [
        {
          link: '/',
          name: 'Home',
        },
        {
          name: 'Blog',
        },
        {
          name: 'Code review',
        },
      ],
    };
  },
  components: {
    SidebarBlog,
    Breadcrumbs,
  },
  computed:{
    ...mapState(['blog'])
  },
  methods: {
    goToPath(tag){
      let post = this.blog.find(item => item.tags.includes(tag))
      this.$router.push(post.img_src)
    },
    back(){
      if(!document.referrer || this.backHistory !== '/blog'){
        Object.defineProperty(document, "referrer", {
          get: function () {
            return `${window.location.origin}/blog`;
          },
        });
        this.$router.push('/blog')
        return
      } else{
        this.$router.go(-1)
      }
    },
    scrolling() {
      const scroll = document.documentElement.scrollTop;
      if (scroll > 50) {
        this.scroll = true;
      } else {
        this.scroll = false;
      }
    },
  },
  created() {
    console.log(history.state.back)
    // history.pushState('blog-home',window.location.href)
    window.history.scrollRestoration = "auto";
    window.addEventListener("scroll", this.scrolling);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  },
}
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.code-review-header {
  .code-review__title {
    padding: 120px 0;
    text-align: end;
    text-transform: uppercase;
    color: $black;
    font-size: 32px;
    line-height: 111%;
    margin: 0;
    @include media(">=tablet") {
      font-size: 40px;
      max-width: 100%;
    }
    @include media(">=tablet-xl") {
      font-size: 56px;
    }
    @include media(">=widescreen") {
      font-size: 72px;
      max-width: 100%;
    }
  }
}

.footer-blog-item {
  display: block;
  padding-top: 20px;
  @include media(">=tablet") {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .code-review-tags {
    margin-top: 40px;
    color: $red;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    @include media(">=tablet") {
      margin-top: 0;
      text-align: center;
    }
    span {
      margin-right: 20px;
      @include media(">=widescreen") {
        margin-right: 40px;
      }
    }
  }
}

.code-review-page {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .link{
    color:$red;
    cursor:pointer;
    &:hover{
      text-decoration: underline;
    }
  }
  .btn-read-more {
    .button-content {
      border-radius: 26px;
      padding: 2px 12px 3px;
      background: $red;
      color: $white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
      width: max-content;
      background-image: linear-gradient($darkRed, $darkRed);
      background-repeat: no-repeat;
      transition: background-size 0s, color 0s;
      background-size: 0% 100%;
      @include media(">=tablet-xl") {
        padding: 6px 24px 8px;
      }
      &:hover {
        background-size: 100% 100%;
        color: $white;
      }
    }
    .icon {
      padding-top: 6px;
      margin-right: 15px;
      transform: rotateY(180deg);
    }
    a {
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .go-back-to-read {
    padding: 16px 0;
    border-bottom-left-radius: 26px 26px;
    border-bottom-right-radius: 26px 26px;
    position: fixed;
    background: $white;
    z-index: 50;
    width: 100vw;
    transition: all 0.3s;
    @include media(">=tablet-xl") {
      padding: 16px 0;
    }
    &.scrolling {
      padding: 5px 0;
      border-bottom: 1px solid $red;
      @include media(">=tablet-xl") {
        padding: 5px 0;
      }
    }
  }
}
.code-review {
  &.section_holder {
    display: flex;
    flex-direction: column-reverse;
    @include media(">=tablet-xl") {
      flex-direction: row;
    }
  }
  .section_title {
    margin-right: 0;
    width: 100%;
    @include media(">=tablet-xl") {
      margin-right: 43px;
      width: 35%;
    }
  }
  .section_content {
    margin-bottom: 60px;
    display: block;
    margin-left: 0;
    width: 100%;
    a {
      color: $red;
    }
    &__item {
      margin-bottom: 40px;
      @include media(">=tablet") {
        margin-bottom: 60px;
      }
      @include media(">=desktop") {
        margin-bottom: 120px;
      }
      &-title {
        &-icon {
          flex-shrink: 0;
          width: 400px;
          height: auto;
          margin-right: 15px;
          @media all and (max-width: 480px) {
            width: 100%;
            margin-right: 0;
          }
          svg {
            max-width: 100%;
            width: 100%;
            max-height: 100%;
            height: 100%;
          }
        }
        &--icon {
          display: flex;
          align-items: flex-end;
          @media all and (max-width: 767px) {
            flex-wrap: wrap;
          }
          @media (min-width: 992px) and (max-width: 1199px) {
            flex-wrap: wrap;
          }
        }
      }
    }
    @include media(">=tablet-xl") {
      margin-left: 37px;
      width: 65%;
      margin-bottom: 0;
    }
    &__paragraph {
      display: flex;
      margin-bottom: 45px;
      &-icon {
        flex-shrink: 0;
        display: inline-block;
        width: 65px;
        height: 65px;
        margin-right: 10px;
        vertical-align: middle;
        @media all and (min-width: 768px) {
          width: 100px;
          height: 100px;
          margin-right: 15px;
        }
        svg {
          max-width: 100%;
          width: 100%;
          max-height: 100%;
          height: 100%;
        }
      }
    }
  }
}

.code-review__banner {
  height: 300px;
  position: relative;
  margin-bottom: 60px;
  @include media(">=tablet") {
    height: 500px;
  }
  @include media(">=desktop") {
    height: 600px;
    margin-bottom: 120px;
  }
  &-img,
  picture {
    display: block;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.code-review__banner-title {
  text-transform: uppercase;
  color: $white;
  font-size: 32px;
  line-height: 111%;
  position: absolute;
  bottom: 20px;
  left: 16px;
  max-width: calc(100% - 32px);
  margin: 0;
  @include media(">=tablet") {
    bottom: 40px;
    font-size: 40px;
    left: 40px;
    max-width: calc(100% - 80px);
  }
  @include media(">=tablet-xl") {
    bottom: 80px;
    left: 40px;
    font-size: 56px;
    max-width: calc(100% - 80px);
  }
  @include media(">=widescreen") {
    bottom: 80px;
    left: 80px;
    font-size: 72px;
    max-width: 1150px;
  }
}
.code-review__img,
picture {
  margin: 0 0 40px;
  display: block;
}
.code-review__customer {
  em {
    display: block;
    margin-top: 32px;
  }
  .button {
    margin-top: 40px;
  }
}
</style>
